import { SvgProps } from './SvgProps';

interface ArrowIconProps extends SvgProps {
    direction: 'up' | 'down' | 'left' | 'right';
}

const ArrowIcon: React.FC<ArrowIconProps> = ({ direction, ...props }) => {
    const getIconRotation = () => {
        switch (direction) {
            case 'left':
                return 'rotate(90deg)';
            case 'right':
                return 'rotate(-90deg)';
            case 'down':
                return 'rotate(0)';
            case 'up':
                return 'rotate(180deg)';
            default:
                return 'rotate(0deg)';
        }
    };

    return (
        <svg
            width="20"
            height="34"
            viewBox="0 0 20 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            style={{
                transform: getIconRotation(),
            }}
        >
            <path
                d="M10 27.625C9.3778 27.625 8.88892 27.1575 8.88892 26.5625V7.4375C8.88892 6.8425 9.3778 6.375 10 6.375C10.6222 6.375 11.1111 6.8425 11.1111 7.4375V26.5625C11.1111 27.1575 10.6222 27.625 10 27.625Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M9.99997 29.75C9.85425 29.7517 9.70977 29.7242 9.57582 29.6693C9.44186 29.6144 9.32141 29.5333 9.2222 29.4313L1.44442 21.9938C0.999973 21.5688 0.999973 20.91 1.44442 20.485C1.88886 20.06 2.57775 20.06 3.0222 20.485L10.0222 27.1788L17.0222 20.485C17.4666 20.06 18.1555 20.06 18.6 20.485C19.0444 20.91 19.0444 21.5688 18.6 21.9938L10.8222 29.4313C10.6 29.6438 10.3111 29.75 10.0444 29.75H9.99997Z"
                fill={props.fill || 'white'}
            />
        </svg>
    );
};

export default ArrowIcon;
