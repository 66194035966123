import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const LogoutIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" {...props} fill="none">
            <path
                d="M12 12C12 14.1213 12 15.182 11.341 15.841C10.682 16.5 9.62132 16.5 7.5 16.5H6.75C4.62868 16.5 3.56802 16.5 2.90901 15.841C2.25 15.182 2.25 14.1213 2.25 12V6C2.25 3.87868 2.25 2.81802 2.90901 2.15901C3.56802 1.5 4.62868 1.5 6.75 1.5H7.5C9.62132 1.5 10.682 1.5 11.341 2.15901C12 2.81802 12 3.87868 12 6"
                stroke={props.fill}
                strokeLinecap="round"
            />
            <path
                d="M12 14.625C13.7678 14.625 14.6517 14.625 15.2008 14.0758C15.75 13.5267 15.75 12.6428 15.75 10.875V7.125C15.75 5.35723 15.75 4.47335 15.2008 3.92417C14.6517 3.375 13.7678 3.375 12 3.375"
                stroke={props.fill}
            />
            <path
                d="M13.5 9L6.75 9M6.75 9L8.625 10.875M6.75 9L8.625 7.125"
                stroke={props.fill}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
