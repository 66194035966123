import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ShareIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="18" height="19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.5 12.59c-.57 0-1.08.225-1.47.578l-5.347-3.113c.037-.172.067-.345.067-.525 0-.18-.03-.352-.067-.525l5.287-3.082a2.24 2.24 0 0 0 1.53.607 2.247 2.247 0 0 0 2.25-2.25 2.247 2.247 0 0 0-2.25-2.25 2.247 2.247 0 0 0-2.25 2.25c0 .18.03.353.068.525L6.03 7.888A2.24 2.24 0 0 0 4.5 7.28a2.247 2.247 0 0 0-2.25 2.25 2.247 2.247 0 0 0 2.25 2.25 2.24 2.24 0 0 0 1.53-.607l5.34 3.12c-.037.157-.06.322-.06.487 0 1.208.982 2.19 2.19 2.19 1.207 0 2.19-.982 2.19-2.19 0-1.207-.982-2.19-2.19-2.19Zm0-9.06c.412 0 .75.338.75.75 0 .413-.338.75-.75.75a.752.752 0 0 1-.75-.75c0-.412.338-.75.75-.75Zm-9 6.75a.752.752 0 0 1-.75-.75c0-.412.338-.75.75-.75s.75.338.75.75c0 .413-.338.75-.75.75Zm9 5.265a.752.752 0 0 1-.75-.75c0-.412.338-.75.75-.75s.75.338.75.75-.338.75-.75.75Z"
                fill="#1B1D1E"
            />
        </svg>
    );
};
