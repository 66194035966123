import React, { useState } from 'react';
import './index.scss';
import Input from '../../../../../Components/input';
import { useSelector } from 'react-redux';
import { USER_REDUCER } from '../../../../../reducers/user-reducer';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../../Style/theme';
import { PencilIcon } from '../../../../../Icons/pencil-icon';
import { UpdatePasswordSchema } from '../../../../../Schemas/UpdatePasswordSchema';
import { useFormValidation } from '../../../../../hooks/use-form-validation';
import useServices from '../../../../../hooks/use-services';
import { toast } from 'react-toastify';
import BackArrow from '../../../../../Components/back-arrow';
import { Tab } from '..';
import Button from '../../../../../Components/button';

interface ManageAccountProps {
    onTabChange: (tab: Tab) => void;
}

const ManageAccount: React.FC<ManageAccountProps> = ({ onTabChange }) => {
    const userStore: USER_REDUCER = useSelector((state: any) => state.userReducer);
    const { t } = useTranslation();

    const [draftPassword, setDraftPassword] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const { validateForm, errors } = useFormValidation(UpdatePasswordSchema);
    const { userService } = useServices();

    const [mode, setMode] = useState<'change_password' | 'default'>('default');

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isValid = await validateForm(draftPassword);

        if (!isValid) return;

        try {
            await userService.updatePassword({
                current_password: draftPassword.currentPassword,
                new_password: draftPassword.newPassword,
            });

            toast.success(t('ManageAccount.password_updated') as string);
            onClickBack();
        } catch (error) {
            console.error(error);
        }
    };

    const onClickBack = () => {
        if (mode === 'default') {
            onTabChange(Tab.Default);
            return;
        }

        setMode('default');
    };

    return (
        <div className="ManageAccount">
            <BackArrow
                color="element-brand-rest-primary"
                onClick={onClickBack}
                title={t('back')}
                titleClassName="text-body-medium"
            />

            {mode === 'default' && (
                <form>
                    <Input label={t('email')} value={userStore?.userInfo?.email} disabled />

                    <Input
                        label={t('ManageAccount.current_password')}
                        value="*******"
                        disabled
                        icon={{
                            right: {
                                icon: <PencilIcon fill={theme['black']} />,
                                onClick: () => setMode('change_password'),
                            },
                        }}
                    />
                </form>
            )}

            {mode === 'change_password' && (
                <form onSubmit={onSubmit}>
                    <Input
                        label={t('ManageAccount.current_password')}
                        value={draftPassword.currentPassword}
                        type="password"
                        name="currentPassword"
                        error={errors.get('currentPassword')}
                        onChange={(value) =>
                            setDraftPassword((prev) => ({ ...prev, currentPassword: value }))
                        }
                    />

                    <Input
                        label={t('ManageAccount.new_password')}
                        value={draftPassword.newPassword}
                        type="password"
                        error={errors.get('newPassword')}
                        name="newPassword"
                        onChange={(value) =>
                            setDraftPassword((prev) => ({ ...prev, newPassword: value }))
                        }
                    />

                    <Input
                        label={t('ManageAccount.confirm_new_password')}
                        value={draftPassword.confirmPassword}
                        type="password"
                        error={errors.get('confirmPassword')}
                        name="confirmPassword"
                        onChange={(value) =>
                            setDraftPassword((prev) => ({ ...prev, confirmPassword: value }))
                        }
                    />
                    <Button type="primary" title={t('save_changes')} />
                </form>
            )}
        </div>
    );
};

export default ManageAccount;
