export namespace CONSTANTS_ROUTING {
    export const ROOT = '/';
    export const HOME = '/landing';
    export const LOGIN = '/login';
    export const FORGOT_PASSWORD = '/forgot-password';
    export const AUTH_PAGE = '/auth';
    export const AUTH_CALLBACK = '/auth/callback';
    export const CREATE_ACCOUNT = '/create-account';
    export const AUTH_HOME = '/home';
    export const DEEP_LINK = '/deep-link';
    export const GALLERY_DETAIL = '/gallery-detail';
    export const TEST_PAGE = '/test';
}
