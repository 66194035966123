import React from 'react';
import './index.scss';

interface ButtonProps {
    title: string;
    onClick?: () => void;
    type: 'primary' | 'tonal' | 'outline' | 'white' | 'danger';
    classNames?: string;
    disabled?: boolean;
    icon?: {
        left?: {
            icon: React.ReactNode;
        };
        right?: {
            icon: React.ReactNode;
        };
    };
}

const Button: React.FC<ButtonProps> = ({ title, onClick, classNames, type, disabled, icon }) => {
    return (
        <button
            className={`Button ${classNames ? classNames : ''} -${type} ${
                disabled ? '-disabled' : ''
            }`}
            onClick={onClick}
            disabled={disabled}
        >
            {icon?.left?.icon && <div className="icon">{icon.left.icon}</div>}
            <span className="text-body-medium">{title}</span>
            {icon?.right?.icon && <div className="icon">{icon.right.icon}</div>}
        </button>
    );
};

export default Button;
