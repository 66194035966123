import * as yup from 'yup';

export const ForgotPasswordSchema = yup.object().shape({
    email: yup
        .string()
        .required('Errors.e_required')
        .email('Errors.e_email')
        .min(5, 'Errors.e_min_5')
        .max(256, 'Errors.e_max_256'),
});
