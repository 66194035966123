import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CONSTANTS_ROUTING } from '../../constants/router';

import Button from '../button';

import './index.scss';

const EmptyTryOn = () => {
    const nav = useNavigate();
    const { t } = useTranslation();
    const onClickButton = () => nav(CONSTANTS_ROUTING.DEEP_LINK);

    return (
        <div className="empty-container">
            <div className="spacer"></div>
            <div className="content">
                <p className="text-body-large-bold">{t('AuthHomePage.empty-gallery')}</p>
            </div>
            <Button
                title={t('AuthHomePage.take-photo').toUpperCase()}
                type="tonal"
                onClick={onClickButton}
            />
        </div>
    );
};

export default EmptyTryOn;
