import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const CameraButtonIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#a)">
                <rect x="4" y="4" width="40" height="40" rx="20" fill="#2B2B2B" />
                <path
                    d="M32 17h-3.17L27 15h-6l-1.83 2H16c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V19c0-1.1-.9-2-2-2Zm0 14H16V19h4.05l1.83-2h4.24l1.83 2H32v12Zm-8-11c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5Zm0 8c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3Z"
                    fill="#fff"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <rect x="4" y="4" width="40" height="40" rx="20" fill="#fff" />
                </clipPath>
            </defs>
        </svg>
    );
};
