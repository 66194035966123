import React, { useEffect, useState } from 'react';
import { StepProps } from '..';
import Button from '../../../Components/button';
import { useFormValidation } from '../../../hooks/use-form-validation';
import { CreateAccountSchema } from '../../../Schemas/CreateAccountSchema';
import Input from '../../../Components/input';
import './step-1.scss';
import { useTranslation } from 'react-i18next';

const Step1: React.FC<StepProps> = ({ draftUser, handleUser, onNextStep }) => {
    const { validateForm, validateSingleInput, errors } = useFormValidation(CreateAccountSchema);
    const [isValidMail, setIsValidMail] = useState(false);
    const { t } = useTranslation();

    const checkIfIsValidMail = async () => {
        const isValid = (await validateSingleInput('email', draftUser.email)).isValid;

        setIsValidMail(isValid);
    };

    const onSubmit = async () => {
        const isValid = await validateForm(draftUser);

        if (!isValid) return;

        onNextStep();
    };

    useEffect(() => {
        if (draftUser.email) {
            checkIfIsValidMail();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draftUser.email]);

    return (
        <div className="Step1 step">
            <div className="text-headline-small">{t('RegisterPage.step_1_title')}</div>
            <div className="text-body-small text-element-greys-secondary mt-10">
                {t('RegisterPage.step_1_subtitle')}
            </div>

            <form className="wrapper-input" onSubmit={onSubmit}>
                <Input
                    onChange={(value, name) => handleUser(name || '', value)}
                    name="email"
                    value={draftUser.email}
                    label={t('email')}
                    autoComplete="off"
                    error={errors.get('email')}
                />

                {draftUser.email && isValidMail && (
                    <div
                        className={`wrapper-password ${
                            draftUser.email && isValidMail ? '-visible' : ''
                        }`}
                    >
                        <Input
                            onChange={(value, name) => handleUser(name || '', value)}
                            name="password"
                            value={draftUser.password}
                            label={t('password')}
                            type="password"
                            error={errors.get('password')}
                        />
                        <Input
                            onChange={(value, name) => handleUser(name || '', value)}
                            name="confirmPassword"
                            value={draftUser.confirmPassword}
                            label={t('confirm_password')}
                            type="password"
                            error={errors.get('confirmPassword')}
                        />
                    </div>
                )}
            </form>

            <Button
                title={t('next').toUpperCase()}
                onClick={onSubmit}
                type="primary"
                disabled={!draftUser.email || !draftUser.password || !draftUser.confirmPassword}
            />
        </div>
    );
};

export default Step1;
