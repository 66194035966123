import React, { useRef, useState, useEffect } from 'react';
import './index.scss';

interface OTPReceiveCodeProps {
    onSubmit: (code: string) => void;
}

const OTPReceiveCode: React.FC<OTPReceiveCodeProps> = ({ onSubmit }: any) => {
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    const [code, setCode] = useState('');

    const SIZE = 4;

    useEffect(() => {
        if (code.length === SIZE) {
            onSubmit(code);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    function handleInput(e: any, index: any) {
        const input = e.target;
        const previousInput = (inputRefs as any)[index - 1];
        const nextInput = inputRefs[index + 1];

        // Update code state with single digit
        const newCode = [...(code as any)];
        // Convert lowercase letters to uppercase
        if (/^[a-z]+$/.test(input.value)) {
            const uc = input.value.toUpperCase();
            newCode[index] = uc;
            (inputRefs as any)[index].current.value = uc;
        } else {
            newCode[index] = input.value;
        }
        setCode(newCode.join(''));

        input.select();

        if (input.value === '') {
            // If the value is deleted, select previous input, if exists
            if (previousInput) {
                previousInput.current.focus();
            }
        } else if (nextInput) {
            // Select next input on entry, if exists
            (nextInput as any).current.select();
        }
    }

    // Select the contents on focus
    function handleFocus(e: any) {
        e.target.select();
    }

    // Handle backspace key
    function handleKeyDown(e: any, index: any) {
        const input = e.target;
        const previousInput = inputRefs[index - 1];

        if ((e.keyCode === 8 || e.keyCode === 46) && input.value === '') {
            e.preventDefault();
            setCode((prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1));
            if (previousInput) {
                (previousInput as any).current.focus();
            }
        }
    }

    // Capture pasted characters
    const handlePaste = (e: any) => {
        const pastedCode = e.clipboardData.getData('text');
        if (pastedCode.length === 4) {
            setCode(pastedCode);
            inputRefs.forEach((inputRef, index) => {
                (inputRef as any).current.value = pastedCode.charAt(index);
            });
        }
    };

    return (
        <div className="OTPReceiveCode">
            {[0, 1, 2, 3].map((index) => (
                <input
                    className="input-otp"
                    key={index}
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleInput(e, index)}
                    ref={inputRefs[index]}
                    autoFocus={index === 0}
                    onFocus={handleFocus}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={handlePaste}
                />
            ))}
        </div>
    );
};

export default OTPReceiveCode;
