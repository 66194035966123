import { QRCodeCanvas } from 'qrcode.react';
import React from 'react';

interface QRCodeProps {
    url: string;

    size?: number;
    bgColor?: string;
    level?: 'L' | 'M' | 'Q' | 'H';
}

const QRCode: React.FC<QRCodeProps> = ({ url, size = 300, bgColor = '#00ff00', level = 'H' }) => {
    return (
        <div className="QRCode">
            <QRCodeCanvas value={url} size={size} bgColor={bgColor} level={level} />
        </div>
    );
};

export default QRCode;
