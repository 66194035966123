import * as yup from 'yup';

export const CreateAccountSchema = yup.object().shape({
    email: yup
        .string()
        .required('Errors.e_required')
        .email('Errors.e_email')
        .min(5, 'Errors.e_min_5')
        .max(256, 'Errors.e_max_256'),
    password: yup
        .string()
        .min(8, 'Errors.e_min_8')
        .max(256, 'Errors.e_max_256')
        .required('Errors.e_required'),
    confirmPassword: yup
        .string()
        .min(8, 'Errors.e_min_8')
        .max(256, 'Errors.e_max_256')
        .required('Errors.e_required')
        .oneOf([yup.ref('password'), null as any], 'Errors.password_does_not_match'),
});
