import { useTranslation } from 'react-i18next';
import './index.scss';
import ArrowIcon from '../../Icons/arrow-icon';
import { CameraIcon } from '../../Icons/camera-icon';
import { theme } from '../../Style/theme';
import { useNavigate } from 'react-router-dom';
import { CONSTANTS_ROUTING } from '../../constants/router';

interface CtaOverlayProps {
    show: boolean;
    onClose: () => void;
}

const CtaOverlay: React.FC<CtaOverlayProps> = ({ show, onClose }) => {
    const { t } = useTranslation();
    const nav = useNavigate();

    const ctaOverlayClassName = show ? 'CtaOverlay' : 'CtaOverlay CtaOverlay--hidden';
    const onCameraClick = () => nav(CONSTANTS_ROUTING.DEEP_LINK);

    const onPressContainer = () => {
        onClose();
    };

    return (
        <div className={ctaOverlayClassName} onClick={onPressContainer}>
            <div className="CtaOverlay__content">
                <div className="CtaOverlay__ctaText">{t('CtaOverlay.cta_text')}</div>
                <ArrowIcon direction="down" className="CtaOverlay__arrowIcon" />
                <button className="CtaOverlay__ctaButton" onClick={onCameraClick}>
                    <div className="CtaOverlay__ctaButtonCircle">
                        <CameraIcon fill={theme['white']} />
                    </div>
                </button>
            </div>
        </div>
    );
};

export default CtaOverlay;
