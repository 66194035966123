import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const FileIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
            <path
                d="M11.17 2L16 6.83V16H2V2H11.17ZM11.17 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V6.83C18 6.3 17.79 5.79 17.41 5.42L12.58 0.59C12.21 0.21 11.7 0 11.17 0ZM4 12H14V14H4V12ZM4 8H14V10H4V8ZM4 4H11V6H4V4Z"
                fill={props.fill}
            />
        </svg>
    );
};
