import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const useImageShare = () => {
    const { t } = useTranslation();

    const handleShare = useCallback(
        async (imageUrl: string) => {
            if (!imageUrl) {
                console.error('No image URL provided');
                return;
            }

            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();

                const file = new File([blob], 'TryOn.png', { type: 'image/png' });

                if (navigator.canShare && navigator.canShare({ files: [file] })) {
                    await navigator.share({
                        files: [file],
                        title: 'Try On Image',
                    });
                } else {
                    throw new Error('Web Share API is not supported');
                }
            } catch (error: any) {
                if (error.name === 'AbortError') {
                    console.log('Share was aborted by the user.');
                } else {
                    console.error('Error sharing the image:', error);
                    toast.error(t('Errors.failed_sharing') as string);
                }
            }
        },
        [t]
    );

    return { handleShare };
};

export default useImageShare;
