import * as yup from 'yup';

export const UpdatePasswordSchema = yup.object().shape({
    currentPassword: yup
        .string()
        .min(8, 'Errors.e_min_8')
        .max(255, 'Errors.e_max_255')
        .required('Errors.e_required'),
    newPassword: yup
        .string()
        .min(8, 'Errors.e_min_8')
        .max(255, 'Errors.e_max_255')
        .required('Errors.e_required'),
    confirmPassword: yup
        .string()
        .min(8, 'Errors.e_min_8')
        .max(255, 'Errors.e_max_255')
        .required('Errors.e_required')
        .oneOf([yup.ref('newPassword'), null as any], 'Errors.password_does_not_match'),
});
