import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../../constants/global';
import { CONSTANTS_ROUTING } from '../../constants/router';
import useServices from '../../hooks/use-services';
import { RootState } from '../../reducers';
import { USER_REDUCER } from '../../reducers/user-reducer';
import UtilsParams from '../../Utils/params';

const RootPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userService } = useServices();
    const nav = useNavigate();
    const userStore: USER_REDUCER = useSelector((state: RootState) => state.userReducer);

    // Fetch the garmentId and partnerId and save it to the store.
    useEffect(() => {
        try {
            const listOfParams = UtilsParams.getEveryParameterFromWindowUrl();

            if (Object.keys(listOfParams).length > 0) {
                dispatch({ type: 'SET_PARAMS', payload: listOfParams });
            }
        } catch (error) {
            console.error(error);
            toast.error(t('ToastError.error_parse_url') as string);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkUserExistingToken = async () => {
        dispatch({ type: 'ADD_LOADING' });
        try {
            const refresh_token = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN);

            if (!refresh_token) {
                nav(CONSTANTS_ROUTING.HOME);
                return;
            }

            const refresh_token_parse = JSON.parse(refresh_token as string);

            if (!refresh_token_parse) {
                nav(CONSTANTS_ROUTING.HOME);
                return;
            }

            const response = await userService.refreshToken({
                refresh_token: refresh_token_parse,
            });

            dispatch({
                type: 'LOGIN',
                payload: {
                    tokens: response,
                    user_id: response.user_id,
                },
            });
            nav(CONSTANTS_ROUTING.AUTH_HOME);
        } catch (error: any) {
            toast.error(error?.message);
            dispatch({
                type: 'LOGOUT',
            });
            nav(CONSTANTS_ROUTING.HOME);
        } finally {
            dispatch({ type: 'REMOVE_LOADING' });
        }
    };

    useEffect(() => {
        checkUserExistingToken();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore?.user_id]);

    return <></>;
};

export default RootPage;
