import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const PencilIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" {...props} fill="none">
            <path
                d="M11.7147 7.51667L12.4814 8.28333L4.93138 15.8333H4.16471V15.0667L11.7147 7.51667ZM14.7147 2.5C14.5064 2.5 14.2897 2.58333 14.1314 2.74167L12.6064 4.26667L15.7314 7.39167L17.2564 5.86667C17.5814 5.54167 17.5814 5.01667 17.2564 4.69167L15.3064 2.74167C15.1397 2.575 14.9314 2.5 14.7147 2.5ZM11.7147 5.15833L2.49805 14.375V17.5H5.62305L14.8397 8.28333L11.7147 5.15833Z"
                fill={props.fill}
            />
        </svg>
    );
};
