import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useIsMobile from '../../hooks/use-is-mobile';
import { CONSTANTS_ROUTING } from '../../constants/router';
import UtilsParent from '../../Utils/parent';

import { GeneralModal } from '../../Components/modal';
import PoweredBy from '../../Components/powered-by';
import Button from '../../Components/button';

import './index.scss';
import { theme } from '../../Style/theme';

import { TowerHolderIcon } from '../../Icons/tower-holder-icon';
import { LaptopIcon } from '../../Icons/laptop-icon';
import { LeftChevronIcon } from '../../Icons/left-chevron-icon';
import { CloseIcon } from '../../Icons';
import { INITIAL_STATE_GLOBAL_PARAMS } from '../../reducers/global-params-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import useServices from '../../hooks/use-services';
import { CONSTANTS } from '../../constants/global';
import AppleSignInButton, { AppleAuthResponse } from 'react-apple-signin-auth';

const AuthentificationPage = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const nav = useNavigate();
    const dispatch = useDispatch();
    const [isModalOpened, setIsModalOpened] = useState(false);
    const paramsStore: INITIAL_STATE_GLOBAL_PARAMS = useSelector(
        (state: RootState) => state.globalParamsReducer
    );
    const { userService } = useServices();

    const onClickClose = () => {
        setIsModalOpened(true);
    };
    console.log(window.location.origin);

    const CALLBACK_URL = window.location.origin + CONSTANTS_ROUTING.AUTH_CALLBACK;

    useEffect(() => {
        // @ts-ignore
        if (window.google) {
            // @ts-ignore
            google.accounts.id.initialize({
                client_id: CONSTANTS.CONFIGURATION.GOOGLE_OAUTH_CLIENTID,
                context: 'signin',
                ux_mode: 'popup',
                login_uri: `${CONSTANTS.CONFIGURATION.GET_API_BASE()}/v1/auth/login/google/callback`,
                callback: async (response: any) => {
                    try {
                        const data = await userService.loginWithGoogle({
                            token: response.credential,
                        });

                        dispatch({
                            type: 'LOGIN',
                            payload: {
                                tokens: {
                                    access_token: data.access_token,
                                    refresh_token: data.refresh_token,
                                },
                                user: data.user_id,
                            },
                        });
                        nav(CONSTANTS_ROUTING.AUTH_HOME);
                    } catch (error) {
                        console.error('Error during API call:', error);
                    }
                },
            });

            // @ts-ignore
            google.accounts.id.renderButton(document.getElementById('g_id_signin'), {
                state: encodeURIComponent(
                    btoa(
                        JSON.stringify({
                            redirect: CALLBACK_URL,
                        })
                    )
                ),
                button_id: 'g_id_signin',
                type: 'standard',
                shape: 'rectangular',
                theme: 'filled_black',
                text: 'signin_with',
                size: 'large',
                logo_alignment: 'left',
                width: '300',
                locale: paramsStore.lng,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickBack = () => nav(-1);
    const onClickCreateAccount = () => nav(CONSTANTS_ROUTING.CREATE_ACCOUNT);
    const onClickLogin = () => {
        nav(CONSTANTS_ROUTING.LOGIN);
    };

    const handleClose = () => {
        setIsModalOpened(false);
    };

    const handleExit = () => {
        try {
            UtilsParent.sendToParent(UtilsParent.MessageCode.IFrameClose);
        } catch (error) {
            console.error(error);
            toast.error(t('ToastError.error_close_iframe') as string);
        }
    };

    return (
        <div className="AuthentificationPage">
            {isModalOpened && (
                <GeneralModal
                    isModalOpened={isModalOpened}
                    title={t('Modal.quit_no_save')}
                    leftButtonText={t('no')}
                    rightButtonText={t('yes')}
                    handleLeftButton={handleClose}
                    handleRightButton={handleExit}
                />
            )}
            <div className="top">
                {isMobile ? (
                    <div className="wrapper-back-icon" onClick={onClickBack}>
                        <LeftChevronIcon width={24} height={24} fill={theme['white']} />
                    </div>
                ) : (
                    <div className="wrapper-close-icon" onClick={onClickClose}>
                        <CloseIcon width={12} height={12} fill={theme['white']} />
                    </div>
                )}

                <div className="top-info">
                    <div className="title text-headline-small text-white">
                        {t('RegisterPage.title_register_page')}
                    </div>
                    <div className="elements">
                        <div className="element">
                            <TowerHolderIcon width={24} fill={theme['white']} />
                            <div className="text-body-large text-white">
                                {t('RegisterPage.first_item_desc')}
                            </div>
                        </div>
                        <div className="element">
                            <LaptopIcon width={isMobile ? 40 : 28} fill={theme['white']} />
                            <div className="text-body-large text-white">
                                {t('RegisterPage.second_item_desc')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="box">
                <div className="content">
                    <div className="top">
                        <Button
                            title={t('RegisterPage.create_account')}
                            type="primary"
                            onClick={onClickCreateAccount}
                        />
                        <Button
                            title={t('RegisterPage.login')}
                            type="outline"
                            onClick={onClickLogin}
                        />
                    </div>

                    <div className="middle">
                        <div className="line" />
                        <div className="text-body-medium or">{t('or')}</div>
                        <div className="line" />
                    </div>

                    <div className="bottom">
                        <div className="btn-sso">
                            <div id="g_id_signin"></div>
                        </div>
                        <div className="btn-sso">
                            <AppleSignInButton
                                buttonExtraChildren={t('LoginPage.connect_apple')}
                                authOptions={{
                                    clientId: CONSTANTS.CONFIGURATION.APPLE_CLIENT_ID,
                                    scope: 'email name',
                                    redirectURI: window.location.origin,
                                    usePopup: true,
                                }}
                                onSuccess={async (credentialsResponse: AppleAuthResponse) => {
                                    const response = await userService.loginWithApple({
                                        token: credentialsResponse.authorization.id_token,
                                    });

                                    dispatch({
                                        type: 'LOGIN',
                                        payload: {
                                            tokens: {
                                                access_token: response.access_token,
                                                refresh_token: response.refresh_token,
                                            },
                                            user: response.user_id,
                                        },
                                    });
                                    nav(CONSTANTS_ROUTING.AUTH_HOME);
                                }}
                                onError={() => {
                                    console.log('error');
                                }}
                                uiType="dark"
                                className="apple-button"
                            />
                        </div>
                    </div>
                </div>
                <PoweredBy />
            </div>
        </div>
    );
};

export default AuthentificationPage;
