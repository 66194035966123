import { useEffect, useState } from 'react';
import { SUPPORTED_LANGUAGES } from '../../constants/language';
import UtilsParent from '../../Utils/parent';
import './index.scss';

const TestPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [isMobileMode, setMobileMode] = useState(false);
    const garmentId = 'protea008';
    const partnerId = '3ucy3u7xmm9ig65ert-14cm9ivjp3l9k-3406hmwdea';
    const [language, setLanguage] = useState(SUPPORTED_LANGUAGES.enUS);

    const onButtonClickDesktop = (language: SUPPORTED_LANGUAGES) => {
        setLanguage(language);
        setMobileMode(false);
        setShowModal(true);
    };

    const onButtonClickMobile = (language: SUPPORTED_LANGUAGES) => {
        setLanguage(language);
        setMobileMode(true);
        setShowModal(true);
    };

    const handleIframeMessage = (event: any) => {
        if (event.origin !== window.location.origin) {
            console.error('Wrong origin');
            return;
        }

        if (event.data === UtilsParent.MessageCode.IFrameClose) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleIframeMessage);

        return () => window.removeEventListener('message', handleIframeMessage);
    }, []);

    return (
        <div className="container">
            <button
                className="tryButton"
                onClick={() => onButtonClickDesktop(SUPPORTED_LANGUAGES.enUS)}
            >
                Try on (Desktop Version)
            </button>
            <button
                className="tryButton"
                onClick={() => onButtonClickMobile(SUPPORTED_LANGUAGES.enUS)}
            >
                Try on (Mobile Version)
            </button>
            <button
                className="tryButton"
                onClick={() => onButtonClickDesktop(SUPPORTED_LANGUAGES.frCA)}
            >
                Try on - FR (Desktop Version)
            </button>
            <button
                className="tryButton"
                onClick={() => onButtonClickMobile(SUPPORTED_LANGUAGES.frCA)}
            >
                Try on - FR (Mobile Version)
            </button>
            <div
                className={isMobileMode ? 'modal-mobile' : 'modal'}
                style={{ display: showModal ? 'flex' : 'none' }}
            >
                {showModal && (
                    <iframe
                        className="iframe"
                        src={`${window.location.origin}?garmentId=${garmentId}&partnerId=${partnerId}&lng=${language}`}
                        title="Try On"
                        sandbox="allow-scripts allow-forms allow-same-origin allow-top-navigation allow-popups"
                        allow="web-share"
                    ></iframe>
                )}
            </div>
        </div>
    );
};

export default TestPage;
