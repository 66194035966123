namespace UtilsParent {
    //if this is changed, make sur to update confluence documentation page.
    export enum MessageCode {
        IFrameClose = '00001',
    }

    export function sendToParent(code: MessageCode) {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment, no-restricted-globals
            parent.postMessage(code, '*');
        } catch (error) {
            console.error(error);
            throw new Error('Error sending message to parent');
        }
    }
}

export default UtilsParent;
