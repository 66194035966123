import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useServices from '../../../../hooks/use-services';
import { CONSTANTS_ROUTING } from '../../../../constants/router';
import { TryOnImage } from '../../../../Services/TryOns';

import EmptyTryOn from '../../../../Components/empty-tryon';

import './index.scss';
import LoadingSpinner from '../../../../Components/loading-spinner';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { INITIAL_STATE_GLOBAL_PARAMS } from '../../../../reducers/global-params-reducer';

const AutHomeGallery = () => {
    const { tryOnService } = useServices();
    const [items, setItems] = useState<TryOnImage[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const nav = useNavigate();
    const paramsStore: INITIAL_STATE_GLOBAL_PARAMS = useSelector(
        (state: RootState) => state.globalParamsReducer
    );

    const getMyTryOn = async () => {
        const res = await tryOnService.fetchUserTryOns(paramsStore.partnerId);
        if (!res) {
            setIsFetching(false);
            return;
        }
        setItems(res.images);
        setIsFetching(false);
    };

    const onClickItem = (item: TryOnImage) =>
        nav(CONSTANTS_ROUTING.GALLERY_DETAIL + '/?id=' + item.id, { state: item });

    useEffect(() => {
        getMyTryOn();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderGallery = () => {
        return (
            <>
                {items.length === 0 ? (
                    <EmptyTryOn />
                ) : (
                    <div className="AutHomeGallery">
                        {items.map((item: TryOnImage) => (
                            <div className="item" onClick={() => onClickItem(item)} key={item.id}>
                                <img src={item.url} alt="Try-On Result" loading="lazy" />
                            </div>
                        ))}
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {isFetching ? (
                <div className="Spinner">
                    <LoadingSpinner enableLoadingText />
                </div>
            ) : (
                renderGallery()
            )}
        </>
    );
};

export default AutHomeGallery;
