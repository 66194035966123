import { AuthTab } from '../../Screens/AuthHome';
import './index.scss';

interface RadioProps {
    options: {
        value: string;
        label: string;
        isSelected?: boolean;
    }[];
    onChange: (id: AuthTab) => void;
}

const Radio: React.FC<RadioProps> = ({ options, onChange }) => {
    return (
        <div className="Radio">
            {options.map((option) => {
                return (
                    <div
                        className={`item ${option.isSelected ? '-selected' : '-not-selected'}`}
                        key={option.value}
                        onClick={() => onChange(option.value as AuthTab)}
                    >
                        <div className="label text-body-medium">{option.label}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default Radio;
