import { CONSTANTS } from '../constants/global';

export interface USER_REDUCER {
    tokens: {
        access_token: string;
        expires_in: string;
        refresh_token: string;
        request_id: string;
    };
    user_id: string;
    userInfo: {
        email: string;
        first_name: string;
        last_name: string;
        consent: string;
        language: string;
    };
    tryOnResultURL: string | null;
}

const initialState: USER_REDUCER = {
    tokens: {
        access_token: '',
        expires_in: '',
        refresh_token: '',
        request_id: '',
    },
    user_id: '',
    userInfo: {
        email: '',
        first_name: '',
        last_name: '',
        consent: '',
        language: '',
    },
    tryOnResultURL: null,
};

const UserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'LOGIN':
            localStorage.setItem(
                CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN,
                JSON.stringify(action.payload.tokens.access_token)
            );
            localStorage.setItem(
                CONSTANTS.LOCALSTORAGE_KEY.REFRESH_TOKEN,
                JSON.stringify(action.payload.tokens.refresh_token)
            );

            return {
                ...state,
                tokens: action.payload.tokens,
                user_id: action.payload.user_id,
            };

        case 'LOGOUT':
            localStorage.clear();

            return initialState;

        case 'SET_USER_INFO':
            return {
                ...state,
                userInfo: action.payload,
            };

        case 'SET_TRY_ON_RESULT':
            return {
                ...state,
                tryOnResultURL: action.payload,
            };

        default:
            return state;
    }
};

export default UserReducer;
