import { CONSTANTS_ROUTING } from '../constants/router';
import AuthentificationPage from '../Screens/Authentification';
import AuthCallbackPage from '../Screens/Authentification/callback';
import AuthHomePage from '../Screens/AuthHome';
import GalleryDetail from '../Screens/AuthHome/Components/Gallery/Detail';
import DeepLinkPage from '../Screens/AuthHome/DeepLinkPage';
import CreateAccountPage from '../Screens/CreateAccount';
import ErrorPage404 from '../Screens/ErrorPage/404';
import ForgotPasswordPage from '../Screens/ForgotPasswordPage';
import HomePage from '../Screens/Home';
import LoginPage from '../Screens/Login';
import RootPage from '../Screens/RootPage';

export interface ROUTE {
    path: string;
    children: () => JSX.Element;
    layout?: {
        show: boolean;
    };
    needLogin: boolean;
}

export const ROUTER_CONFIG: ROUTE[] = [
    {
        path: CONSTANTS_ROUTING.ROOT,
        needLogin: false,
        children: RootPage,
    },
    {
        path: CONSTANTS_ROUTING.HOME,
        children: HomePage,
        needLogin: false,
    },
    {
        path: CONSTANTS_ROUTING.AUTH_PAGE,
        children: AuthentificationPage,
        layout: {
            show: false,
        },
        needLogin: false,
    },
    {
        path: CONSTANTS_ROUTING.CREATE_ACCOUNT,
        children: CreateAccountPage,
        needLogin: false,
    },
    {
        path: CONSTANTS_ROUTING.AUTH_HOME,
        children: AuthHomePage,
        needLogin: true,
    },
    {
        path: CONSTANTS_ROUTING.GALLERY_DETAIL,
        children: GalleryDetail,
        needLogin: true,
    },
    {
        path: CONSTANTS_ROUTING.LOGIN,
        children: LoginPage,
        needLogin: false,
    },
    {
        path: CONSTANTS_ROUTING.DEEP_LINK,
        children: DeepLinkPage,
        needLogin: true,
    },
    {
        path: CONSTANTS_ROUTING.AUTH_CALLBACK,
        children: AuthCallbackPage,
        needLogin: false,
    },
    {
        path: CONSTANTS_ROUTING.FORGOT_PASSWORD,
        children: ForgotPasswordPage,
        needLogin: false,
    },
    {
        path: '*',
        children: ErrorPage404,
        needLogin: false,
    },
];
