import UtilsValidation from '../Utils/validation';
import { CONSTANTS } from '../constants/global';
import { SUPPORTED_LANGUAGES } from '../constants/language';
import i18next from 'i18next';

export interface INITIAL_STATE_GLOBAL_PARAMS {
    ctaHasBeenCalled: boolean;
    lng: SUPPORTED_LANGUAGES;
    partnerId: string;
    garmentId: string;
    globalLoading: number;
}

const initialState: INITIAL_STATE_GLOBAL_PARAMS = {
    ctaHasBeenCalled: true,
    lng: SUPPORTED_LANGUAGES.enEN,
    partnerId: '',
    garmentId: '',
    globalLoading: 0,
};

const getLngToUse = (action: any) => {
    return action.payload?.lng && UtilsValidation.isValideLng(action.payload?.lng)
        ? action.payload?.lng
        : navigator?.language && UtilsValidation.isValideLng(navigator?.language)
        ? navigator?.language
        : SUPPORTED_LANGUAGES.enUS;
};

const globalParamsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_CTA_HAS_BEEN_CALLED':
            localStorage.setItem(
                CONSTANTS.LOCALSTORAGE_KEY.CTA_HAS_BEEN_CALLED,
                JSON.stringify(action.payload)
            );

            return {
                ...state,
                ctaHasBeenCalled: action.payload,
            };
        case 'SET_PARAMS':
            const lngToUse = getLngToUse(action);
            i18next.changeLanguage(lngToUse?.split('-')?.[0]);
            return {
                ...state,
                lng: lngToUse,
                partnerId: action.payload?.partnerId,
                garmentId: action.payload?.garmentId,
            };

        case 'ADD_LOADING':
            return {
                ...state,
                globalLoading: state.globalLoading + 1,
            };

        case 'REMOVE_LOADING':
            return {
                ...state,
                globalLoading: state.globalLoading - 1,
            };

        default:
            return state;
    }
};

export default globalParamsReducer;
