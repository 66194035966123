import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { CONSTANTS_ROUTING } from '../constants/router';
import useIsMobile from '../hooks/use-is-mobile';
import UtilsParent from '../Utils/parent';

import { GeneralModal } from '../Components/modal';
import { CloseIcon } from '../Icons';

import './default-layout.scss';
import { CONSTANTS } from '../constants/global';
import { useDispatch } from 'react-redux';

interface DefaultLayoutProps {
    children: React.ReactNode;
}

const DefaultLayout: React.FunctionComponent<DefaultLayoutProps> = ({ children }) => {
    const [showCloseIcon, setShowCloseIcon] = useState<boolean>(true);
    const [isModalOpened, setIsModalOpened] = useState(false);

    const isMobile = useIsMobile();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const onClickClose = () => {
        setIsModalOpened(true);
    };

    const handleClose = () => {
        setIsModalOpened(false);
    };

    const handleExit = () => {
        try {
            UtilsParent.sendToParent(UtilsParent.MessageCode.IFrameClose);
        } catch (error) {
            console.error(error);
            toast.error(t('ToastError.error_close_iframe') as string);
        }
    };

    useEffect(() => {
        setShowCloseIcon(true);

        if (window.location.pathname.includes(CONSTANTS_ROUTING.CREATE_ACCOUNT) && isMobile) {
            setShowCloseIcon(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname, isMobile]);

    useEffect(() => {
        const localStorageCtaHasBeenCalled = localStorage.getItem(
            CONSTANTS.LOCALSTORAGE_KEY.CTA_HAS_BEEN_CALLED
        );
        if (localStorageCtaHasBeenCalled && JSON.parse(localStorageCtaHasBeenCalled)) {
            dispatch({ type: 'SET_CTA_HAS_BEEN_CALLED', payload: true });
        } else {
            dispatch({ type: 'SET_CTA_HAS_BEEN_CALLED', payload: false });
        }
    }, [dispatch]);

    return (
        <div className="DefaultLayout">
            {isModalOpened && (
                <GeneralModal
                    isModalOpened={isModalOpened}
                    title={t('Modal.quit_no_save')}
                    leftButtonText={t('no')}
                    rightButtonText={t('yes')}
                    handleLeftButton={handleClose}
                    handleRightButton={handleExit}
                />
            )}
            {showCloseIcon && (
                <div className="close-icon cursor-pointer" onClick={onClickClose}>
                    <CloseIcon fill="black" />
                </div>
            )}

            {children}
        </div>
    );
};

export default DefaultLayout;
