import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONSTANTS_ROUTING } from '../../constants/router';
import useServices from '../../hooks/use-services';

import Button from '../button';

import './index.scss';

interface DeleteAccountModalProps {
    isModalOpened: boolean;
    setIsModalOpened: any;
}

export const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
    isModalOpened,
    setIsModalOpened,
}) => {
    const { t } = useTranslation();
    const { userService } = useServices();
    const dispatch = useDispatch();
    const nav = useNavigate();

    Modal.setAppElement(document.getElementById('portal-root') as HTMLElement);

    const handleDelete = async () => {
        await userService.deleteUser();
        toast.success(t('Modal.delete_success') as string);
        setIsModalOpened(false);
        dispatch({ type: 'LOGOUT' });
        nav(CONSTANTS_ROUTING.LOGIN);
    };

    const handleCancel = () => {
        setIsModalOpened(false);
    };

    return (
        <div className="DeleteModal">
            <Modal
                overlayClassName="delete-overlay"
                className="delete-container"
                isOpen={isModalOpened}
            >
                <div className="delete-texts">
                    <h2 className="delete-title text-title-large-bold">
                        {t('Modal.delete_title')}
                    </h2>
                    <p>{t('Modal.delete_description')}</p>
                    <p>{t('Modal.delete_question')}</p>
                </div>
                <div className="delete-buttons">
                    <Button title={t('Modal.delete_button')} type="danger" onClick={handleDelete} />
                    <Button
                        title={t('Modal.cancel_button')}
                        type="outline"
                        onClick={handleCancel}
                    />
                </div>
            </Modal>
        </div>
    );
};
