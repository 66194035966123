import React, { useState } from 'react';
import './index.scss';
import { EyeIcon } from '../../Icons/eye-icon';
import { useTranslation } from 'react-i18next';
import { theme } from '../../Style/theme';

interface InputProps {
    onChange?: (value: string, name?: string) => void;
    name?: string;
    value?: string;
    label?: string;
    type?:
        | 'button'
        | 'checkbox'
        | 'color'
        | 'date'
        | 'datetime-local'
        | 'email'
        | 'file'
        | 'hidden'
        | 'image'
        | 'month'
        | 'number'
        | 'password'
        | 'radio'
        | 'range'
        | 'reset'
        | 'search'
        | 'submit'
        | 'tel'
        | 'text'
        | 'time'
        | 'url'
        | 'week';
    autoComplete?: string;
    error?: string[];
    disabled?: boolean;
    onClick?: () => void;
    placeholder?: string;
    icon?: {
        right?: {
            icon: React.ReactNode;
            onClick?: () => void;
        };
    };
}

const Input: React.FC<InputProps> = ({
    onChange,
    name,
    value,
    label,
    type = 'text',
    autoComplete,
    error,
    disabled,
    onClick,
    placeholder,
    icon,
}) => {
    const { t } = useTranslation();
    const [showValue, setShowValue] = useState(false);
    const EYE_COLOR = theme['surface-grey-opposite'];

    return (
        <div
            className={`Input ${type ? '-password' : ''} ${
                (error?.length || 0) > 0 ? '-error' : ''
            }`}
        >
            {label && <label className="text-body-small">{label}</label>}

            <input
                onChange={(event) => onChange && onChange(event.target.value, event.target.name)}
                name={name}
                value={value}
                type={type === 'password' ? (showValue ? 'text' : 'password') : type}
                autoComplete={autoComplete}
                disabled={disabled}
                onClick={onClick}
                placeholder={placeholder}
            />

            {type === 'password' ? (
                <EyeIcon
                    fill={EYE_COLOR}
                    className="eye cursor-pointer"
                    onClick={() => setShowValue((prev) => !prev)}
                    width={20}
                />
            ) : null}

            {icon?.right?.icon && (
                <div className="icon-right" onClick={icon.right.onClick}>
                    {icon.right.icon}
                </div>
            )}

            <div className="wrapper-error">
                {error?.map((err, index) => (
                    <div key={index} className="text-error text-label-medium">
                        {t(err)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Input;
