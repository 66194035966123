import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';

interface LoadingSpinnerProps {
    fullScreen?: boolean;
    enableLoadingText?: boolean;
}

const LoadingSpinner = ({ fullScreen, enableLoadingText }: LoadingSpinnerProps) => {
    const { t } = useTranslation();
    return (
        <div className={`LoadingSpinner ${fullScreen ? 'fullScreen' : ''}`}>
            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            {enableLoadingText && <p className="text-body-small">{t('loading_spinner')}</p>}
        </div>
    );
};

export default LoadingSpinner;
