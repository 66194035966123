import React from 'react';
import './index.scss';
import { CameraIcon } from '../../../../Icons/camera-icon';
import { theme } from '../../../../Style/theme';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CONSTANTS_ROUTING } from '../../../../constants/router';

interface AuthHomeEmptyViewProps {}

const AuthHomeEmptyView: React.FC<AuthHomeEmptyViewProps> = () => {
    const { t } = useTranslation();
    const nav = useNavigate();

    const onClickCamera = () => nav(CONSTANTS_ROUTING.DEEP_LINK);

    return (
        <div className="AuthHomeEmptyView">
            <div className="text-body-large-bold mt-10">{t('AuthHomePage.empty_title')}</div>

            <div className="text-body-medium">{t('AuthHomePage.empty_desc')}</div>

            <div className="wrapper-picture" onClick={onClickCamera}>
                <div className="little-circle">
                    <CameraIcon fill={theme['white']} />
                </div>
            </div>
        </div>
    );
};

export default AuthHomeEmptyView;
