import React from 'react';
import { To, useNavigate } from 'react-router-dom';

import { LeftChevronIcon } from '../../Icons/left-chevron-icon';

import './index.scss';
import { theme } from '../../Style/theme';

interface BackArrowProps {
    navigationTarget?: string | number;
    color?: keyof typeof theme;
    title?: string;
    titleClassName?: string;
    onClick?: () => void;
}

const BackArrow: React.FC<BackArrowProps> = ({
    navigationTarget,
    color,
    title,
    titleClassName,
    onClick,
}) => {
    const nav = useNavigate();
    const handleClick = () => {
        if (navigationTarget !== undefined) {
            nav(navigationTarget as To);
        }

        // Invoke onClick if provided
        onClick?.();
    };

    return (
        <div className="back-arrow-container">
            <LeftChevronIcon
                onClick={handleClick}
                className="back-arrow"
                fill={
                    color ? theme[color as keyof typeof theme] : theme['element-brand-rest-primary']
                }
            />
            {title && (
                <span className={titleClassName} onClick={handleClick}>
                    {title}
                </span>
            )}
        </div>
    );
};

export default BackArrow;
