import React, { useState } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../Components/button';
import { ShareIcon } from '../../../../../Icons/share-icon';
import { ShoppingBagIcon } from '../../../../../Icons/shopping-bag-icon';

import './index.scss';
import { TrashButtonIcon } from '../../../../../Icons/trash-button-icon';
import { GeneralModal } from '../../../../../Components/modal';
import useServices from '../../../../../hooks/use-services';
import useImageShare from '../../../../../hooks/use-image-share';
import BackArrow from '../../../../../Components/back-arrow';
import { CONSTANTS_ROUTING } from '../../../../../constants/router';

const GalleryDetail = () => {
    const { state } = useLocation();
    const nav = useNavigate();
    const onClickBack = () =>
        nav(CONSTANTS_ROUTING.AUTH_HOME as To, { state: { origin: 'detail' } });
    const { t } = useTranslation();
    const [isModalOpened, setIsModalOpened] = useState(false);
    const isShareSupported = navigator?.share !== undefined;
    const { handleShare } = useImageShare();

    const onClickClose = () => {
        setIsModalOpened(false);
    };

    const { tryOnService } = useServices();

    const handleOnShop = () => {
        //TODO: Handle shopping the look
        console.log('Shop this look');
    };

    const handleShareButton = async () => {
        /*
        We have to fetch the image URL from the getTryonStatus call, as the image from state.url will get blocked
        due to CORS when calling the handleShare fetch function.
        */
        const res = await tryOnService.getTryonStatus(state.id);
        if (!res) return;
        handleShare(res.output_2d.url);
    };

    const handleDeleteTryOn = async () => {
        const requestId = [state.id];
        try {
            await tryOnService.deleteTryOn(requestId);
            onClickBack();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {isModalOpened && (
                <GeneralModal
                    isModalOpened={isModalOpened}
                    title={t('ModalGallery.title')}
                    leftButtonText={t('no')}
                    rightButtonText={t('yes')}
                    handleLeftButton={onClickClose}
                    handleRightButton={handleDeleteTryOn}
                />
            )}

            <div className="GalleryDetail">
                <BackArrow onClick={onClickBack} color="black" />
                <div className="header">
                    <h1 className="text-headline-medium">{t('GalleryDetail.tab_title')}</h1>
                </div>
                <div className="image-container">
                    <img src={state.url} alt={state.id} />
                    <TrashButtonIcon
                        className="trash-button"
                        width={48}
                        height={48}
                        onClick={() => setIsModalOpened(true)}
                    />
                </div>

                <div className="buttons">
                    {isShareSupported && (
                        <Button
                            title={t('GalleryDetail.share')}
                            type="outline"
                            onClick={handleShareButton}
                            icon={{ left: { icon: <ShareIcon /> } }}
                        />
                    )}
                    <Button
                        title={t('GalleryDetail.shop_look')}
                        type="tonal"
                        onClick={handleOnShop}
                        classNames="text-body-medium"
                        icon={{ left: { icon: <ShoppingBagIcon /> } }}
                    />
                </div>
            </div>
        </>
    );
};

export default GalleryDetail;
