import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useFormValidation = (
    schema: yup.ObjectSchema
): {
    errors: Map<string, string[]>;
    validateForm: (values: object) => Promise<boolean>;
    resetErrors: () => void;
    setErrors: (errors: Map<string, string[]>) => void;
    validateSingleInput: (
        fieldName: string,
        value: any
    ) => Promise<{ isValid: boolean; errors: string[] }>;
} => {
    const [errors, setErrors] = React.useState(new Map<string, string[]>());
    const { t } = useTranslation();

    const resetErrors = (): void => {
        setErrors(new Map<string, string[]>());
    };

    const validateForm = async (values: object): Promise<boolean> => {
        try {
            await schema.validate(values, { abortEarly: false });
            resetErrors();
            return true;
        } catch (validationErrors: any) {
            const errors = new Map<string, string[]>();
            if (validationErrors.inner) {
                for (const error of validationErrors.inner) {
                    errors.set(
                        error.path,
                        (errors.get(error.path) ?? []).concat(t(`${error.errors}`) + '. ')
                    );
                }
            }

            console.log('error: ', errors);
            setErrors(errors);
            return false;
        }
    };

    const validateSingleInput = async (
        fieldName: string,
        value: any
    ): Promise<{ isValid: boolean; errors: string[] }> => {
        try {
            await schema.validateAt(fieldName, { [fieldName]: value }, { abortEarly: false });
            return { isValid: true, errors: [] };
        } catch (validationErrors: any) {
            const errors: string[] = [];

            if (validationErrors.inner) {
                for (const error of validationErrors.inner) {
                    errors.push(t(error.message));
                }
            }
            return { isValid: false, errors };
        }
    };

    return { errors, validateForm, resetErrors, setErrors, validateSingleInput };
};
