import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TestPage from '../Screens/TestPage';
import DefaultLayout from '../Layouts/default-layout';

import { ROUTER_CONFIG } from './config';
import { CONSTANTS } from '../constants/global';
import { CONSTANTS_ROUTING } from '../constants/router';
import { RootState } from '../reducers';
import { USER_REDUCER } from '../reducers/user-reducer';

const Router = () => {
    const userStore: USER_REDUCER = useSelector((state: RootState) => state.userReducer);

    return (
        <Routes>
            {CONSTANTS.CONFIGURATION.SHOW_TEST_ROUTE && (
                <Route path={CONSTANTS_ROUTING.TEST_PAGE} element={<TestPage />} />
            )}
            {ROUTER_CONFIG.map((route) => {
                const Content = route.children;
                const needLogin = route?.needLogin;

                return (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={
                            needLogin && !userStore?.tokens.access_token ? (
                                <Navigate to={CONSTANTS_ROUTING.HOME} />
                            ) : route?.layout?.show === false ? (
                                <Content />
                            ) : (
                                <DefaultLayout>
                                    <Content />
                                </DefaultLayout>
                            )
                        }
                    />
                );
            })}
        </Routes>
    );
};

export default Router;
