import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const TrashButtonIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="48" height="48" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#a)">
                <rect x="4" y="4" width="40" height="40" rx="20" fill="#2B2B2B" />
                <path
                    d="M28 21v10h-8V21h8Zm-1.5-6h-5l-1 1H17v2h14v-2h-3.5l-1-1Zm3.5 4H18v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V19Z"
                    fill="#fff"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <rect x="4" y="4" width="40" height="40" rx="20" fill="#fff" />
                </clipPath>
            </defs>
        </svg>
    );
};
