import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const LockIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" {...props} fill="none">
            <path
                fill={props.fill}
                d="M37.5 17.708h-2.083v-4.166c0-5.75-4.667-10.417-10.417-10.417S14.583 7.792 14.583 13.542v4.166H12.5a4.179 4.179 0 0 0-4.167 4.167v20.833a4.179 4.179 0 0 0 4.167 4.167h25a4.179 4.179 0 0 0 4.167-4.167V21.875a4.179 4.179 0 0 0-4.167-4.167Zm-18.75-4.166A6.242 6.242 0 0 1 25 7.292a6.242 6.242 0 0 1 6.25 6.25v4.166h-12.5v-4.166ZM37.5 42.708h-25V21.875h25v20.833ZM25 36.458a4.179 4.179 0 0 0 4.167-4.166A4.179 4.179 0 0 0 25 28.125a4.179 4.179 0 0 0-4.167 4.167A4.179 4.179 0 0 0 25 36.458Z"
            />
        </svg>
    );
};
