import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CONSTANTS_ROUTING } from '../../constants/router';
import { useFormValidation } from '../../hooks/use-form-validation';
import { ForgotPasswordSchema } from '../../Schemas/ForgotPasswordSchema';

import BackArrow from '../../Components/back-arrow';
import Button from '../../Components/button';
import Input from '../../Components/input';
import { LockIcon } from '../../Icons/lock-icon';

import './index.scss';
import useServices from '../../hooks/use-services';
import { ForwardToInboxIcon } from '../../Icons/forward-to-inbox-icon';
import { toast } from 'react-toastify';
import { theme } from '../../Style/theme';

enum Step {
    EnterEmail,
    EmailSent,
}

const ForgotPasswordPage = () => {
    const { t } = useTranslation();
    const [userEmail, setUserEmail] = useState('');
    const [step, setStep] = useState<Step>(Step.EnterEmail);

    const { validateForm, errors } = useFormValidation(ForgotPasswordSchema);
    const { userService } = useServices();

    const sendEmail = async () => {
        try {
            await userService.forgotPassword({ email: userEmail });
            setStep(Step.EmailSent);
            toast.success(t('ForgotPassword.email_sent') as string);
        } catch (error) {
            console.log(error);
        }
    };

    const handleButtonClick = async (e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        if (step === Step.EnterEmail) {
            const isValid = await validateForm({ email: userEmail });
            if (!isValid) return;

            setStep(Step.EmailSent);
            sendEmail();
        } else {
            sendEmail();
        }
    };

    const EnterEmailStep = () => {
        return (
            <div className="stepOne">
                <LockIcon fill={theme['element-greys-primary']} />
                <p className="text-body-medium stepOneDesc">
                    {t('ForgotPassword.step_one_description')}
                </p>
                <form className="wrapper-input" onSubmit={handleButtonClick}>
                    <Input
                        type="email"
                        value={userEmail}
                        name="email"
                        label={t('email')}
                        onChange={(value) => setUserEmail(value)}
                        error={errors.get('email')}
                    />
                </form>
            </div>
        );
    };

    const EmailSentStep = () => {
        return (
            <div className="stepTwo">
                <ForwardToInboxIcon fill={theme['element-greys-primary']} />
                <h2 className="text-headline-small stepTwoTitle">
                    {t('ForgotPassword.step_two_title')}
                </h2>
                <p className="text-body-medium stepTwoDesc">
                    {t('ForgotPassword.step_two_description')}
                </p>
            </div>
        );
    };

    return (
        <div className="ForgotPasswordPage">
            <BackArrow color="element-greys-primary" navigationTarget={CONSTANTS_ROUTING.LOGIN} />
            <div className="steps">
                {step === Step.EnterEmail ? EnterEmailStep() : EmailSentStep()}
            </div>
            <Button
                classNames="stepButton"
                type="tonal"
                title={step === Step.EnterEmail ? t('next') : t('ForgotPassword.resend')}
                onClick={handleButtonClick}
            />
        </div>
    );
};

export default ForgotPasswordPage;
