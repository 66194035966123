import { toast } from 'react-toastify';
import Service from '..';
import { SUPPORTED_LANGUAGES } from '../../constants/language';
import translateErrorMessage from '../../Utils/api-errors';

interface CreateUserDto {
    email: string;
    password: string;
    language: string;
}

interface LoginUserDto {
    email: string;
    password: string;
}

interface CreateAccountResendCodeDto {
    email: string;
}

interface ConfirmAccountDto {
    email: string;
    confirmation_code: string;
}

interface UserDto {
    id: string;
    email: string;
    first_name?: string;
    last_name?: string;
    consent?: string;
    language?: string;
}

interface LoginUserResponseDto {
    access_token: string;
    expires_in: string;
    refresh_token: string;
    request_id: string;
    user_id: string;
}

interface ForgotPasswordDto {
    email: string;
}

interface RefreshTokenDto {
    refresh_token: string;
}

interface UpdatePasswordDto {
    current_password: string;
    new_password: string;
}

interface ConsentDto {
    key: string;
    category: string;
    language: SUPPORTED_LANGUAGES;
}

interface ConsentResponseDto {
    request_id: string;
    key: string;
    category: string;
    value: string;
    attachments: [content_type: string, name: string, value: string];
}

class UserService extends Service {
    async createUser(request: CreateUserDto): Promise<UserDto> {
        const response = await this.post('/v2/user', request);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            throw new Error(errorMessage);
        }

        return await response.json();
    }

    async fetchConsent(request: ConsentDto): Promise<ConsentResponseDto> {
        const response = await this.get(
            `/v1/templates/${request.category}/${request.key}`,
            false,
            request.language
        );

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }

        return await response.json();
    }

    async loginUser(request: LoginUserDto): Promise<LoginUserResponseDto> {
        const response = await this.post('/v1/auth/login', request);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }

        return await response.json();
    }

    async loginWithGoogle(request: { token: string }): Promise<LoginUserResponseDto> {
        const response = await this.post('/v1/auth/login/google/token', request);
        if (!response) {
            throw new Error('No response');
        }
        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }
        return await response.json();
    }

    async loginWithApple(request: { token: string }): Promise<LoginUserResponseDto> {
        const response = await this.post('/v1/auth/login/apple/token', request);
        if (!response) {
            throw new Error('No response');
        }
        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }
        console.log(response);
        return await response.json();
    }

    async createAccountResendCode(request: CreateAccountResendCodeDto): Promise<void> {
        const response = await this.post('/v1/user/resendconfirmationcode', request);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }
    }

    async confirmAccount(request: ConfirmAccountDto): Promise<void> {
        const response = await this.post('/v1/user/confirmsignup', request);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }
    }

    async refreshToken(request: RefreshTokenDto): Promise<LoginUserResponseDto> {
        const response = await this.post('/v1/auth/refresh', request);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }

        return await response.json();
    }

    async forgotPassword(request: ForgotPasswordDto): Promise<void> {
        const response = await this.post('/v1/auth/forgotpassword', request);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }
    }

    async getUserInfo(): Promise<UserDto> {
        const response = await this.get('/v2/user', true);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }

        return await response.json();
    }

    async updatePassword(request: UpdatePasswordDto): Promise<void> {
        const response = await this.post('/v1/auth/change-password', request, true);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }
    }

    async deleteUser(): Promise<void> {
        const response = await this.delete('/v2/user', true);
        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(errorMessage);
            throw new Error(errorMessage);
        }
    }
}

export default UserService;
