export namespace CONSTANTS {
    export const BREAKPOINT_MOBILE = 645;
    export const DEFAULT_POLLING_INTERVAL = 3000;

    export namespace LOCALSTORAGE_KEY {
        export const ACCESS_TOKEN = '@access_token';
        export const REFRESH_TOKEN = '@refresh_token';
        export const CTA_HAS_BEEN_CALLED = '@cta_has_been_called';
        export const TRY_ON_BLOB = '@try_on_blob';
    }

    export namespace API_URL {
        export const DEV = 'https://api.dev.spreeai.com';
        export const STAGE = 'https://api.stage.spreeai.com';
        export const PROD = 'https://api.spreeai.com';
    }

    export namespace CONFIGURATION {
        export const INTERVAL_TIME_POLL_MS = 2000;
        export const SHOW_TEST_ROUTE: boolean = false;
        export const APPLE_CLIENT_ID = 'com.spreeai.api';
        export const GOOGLE_OAUTH_CLIENTID =
            '596736041492-rubn05mdvnbcsntkb13rrh91sgd5j5bl.apps.googleusercontent.com';
        export const GET_API_BASE = () => {
            const isDev =
                window.location.hostname.includes('dev.') ||
                window.location.hostname.includes('localhost');
            if (isDev) {
                return CONSTANTS.API_URL.DEV;
            }

            const isStage =
                window.location.hostname.includes('stage.') ||
                window.location.hostname.includes('staging.');
            if (isStage) {
                return CONSTANTS.API_URL.STAGE;
            }

            return CONSTANTS.API_URL.PROD;
        };
    }
}
