import React from 'react';
import { useSelector } from 'react-redux';
import { INITIAL_STATE_GLOBAL_PARAMS } from '../../reducers/global-params-reducer';
import { RootState } from '../../reducers';
import LoadingSpinner from '../loading-spinner';

import './index.scss';

const GlobalLoading = () => {
    const paramsStore: INITIAL_STATE_GLOBAL_PARAMS = useSelector(
        (state: RootState) => state.globalParamsReducer
    );

    if (paramsStore.globalLoading > 0) {
        return (
            <div className="GlobalLoading">
                <LoadingSpinner fullScreen enableLoadingText />
            </div>
        );
    }

    return null;
};

export default GlobalLoading;
