import Service from '..';

interface DeepLinkParams {
    parameters: {
        garmentId: string;
        token: string;
        refreshToken: string;
        source: string;
        partnerId: string;
    };
    partner_id: string;
}

interface DeepLinkResponseDTO {
    link: string;
}

class DeepLinkService extends Service {
    async createDeepLink(request: DeepLinkParams): Promise<DeepLinkResponseDTO> {
        const response = await this.post(`/v1/deeplinks/link`, request);

        if (!response) {
            throw new Error('Failed to create deep link');
        }

        return await response.json();
    }
}

export default DeepLinkService;
