import { useEffect } from 'react';
import UtilsParams from '../../Utils/params';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CONSTANTS_ROUTING } from '../../constants/router';

const AuthCallbackPage = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();

    const handleCallback = async () => {
        const params = UtilsParams.getEveryParameterFromWindowUrl();
        try {
            const decodedToken = atob(decodeURIComponent(params.token)); // Decode the Base64 token
            const response = JSON.parse(decodedToken);
            if (decodedToken) {
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        tokens: {
                            access_token: response.access_token,
                            refresh_token: response.refresh_token,
                        },
                        user: response.user_id,
                    },
                });
            }
        } catch (error) {
            // Handle error here
            console.error('Error decoding token:', error);
        }

        nav(CONSTANTS_ROUTING.HOME);
    };

    useEffect(() => {
        handleCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <></>;
};

export default AuthCallbackPage;
