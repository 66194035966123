import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './index.scss';
import PoweredBy from '../../Components/powered-by';
import useServices from '../../hooks/use-services';
import { useTranslation } from 'react-i18next';
import Input from '../../Components/input';
import Button from '../../Components/button';
import { useDispatch } from 'react-redux';
import { CONSTANTS_ROUTING } from '../../constants/router';
import translateErrorMessage from '../../Utils/api-errors';
import { ApiErrors } from '../../constants/apiErrors';
import BackArrow from '../../Components/back-arrow';

const LoginPage = () => {
    const nav = useNavigate();
    const { userService } = useServices();
    const [draftUser, setDraftUser] = useState({
        email: '',
        password: '',
    });
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onLoginUser = async () => {
        try {
            const response = await userService.loginUser(draftUser);

            dispatch({
                type: 'LOGIN',
                payload: {
                    tokens: response,
                    user: response.user_id,
                },
            });

            nav(CONSTANTS_ROUTING.AUTH_HOME);
        } catch (error: any) {
            const parsedErrorMessage = translateErrorMessage(error?.message);

            if (parsedErrorMessage.errorType === ApiErrors.ACCOUNT_NOT_CONFIRMED) {
                nav(CONSTANTS_ROUTING.CREATE_ACCOUNT, {
                    state: { email: draftUser.email, needConfirm: true },
                });
            }
        }
    };

    const handleUser = (key: string, value: string) => {
        setDraftUser((prev) => ({ ...prev, [key]: value }));
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        draftUser.email = draftUser.email.trim();
        onLoginUser();
    };

    const onClickForgetPassword = async () => {
        nav(CONSTANTS_ROUTING.FORGOT_PASSWORD);
    };

    return (
        <div className="LoginPage">
            <BackArrow color="black" navigationTarget={CONSTANTS_ROUTING.AUTH_PAGE} />

            <div className="wrapper-content">
                <div className="text-headline-small">{t('LoginPage.login_title')}</div>
                <div className="text-body-small text-element-greys-secondary mt-10">
                    {t('LoginPage.login_subtitle')}
                </div>

                <form className="wrapper-input" onSubmit={onSubmit}>
                    <Input
                        onChange={(value, name) => handleUser(name || '', value)}
                        name="email"
                        value={draftUser.email}
                        label={t('email')}
                        autoComplete="username"
                    />

                    <Input
                        onChange={(value, name) => handleUser(name || '', value)}
                        name="password"
                        value={draftUser.password}
                        label={t('password')}
                        type="password"
                        autoComplete="current-password"
                    />
                    <Button
                        title={t('next').toUpperCase()}
                        type="primary"
                        disabled={!draftUser.email.trim() || !draftUser.password.trim()}
                    />
                </form>

                <div className="forget-password text-body-medium" onClick={onClickForgetPassword}>
                    {t('LoginPage.forget_password')}
                </div>

                <PoweredBy />
            </div>
        </div>
    );
};

export default LoginPage;
