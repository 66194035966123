import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const LeftChevronIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" {...props} fill="none">
            <path
                d="M15 6.7051C14.61 6.3151 13.98 6.3151 13.59 6.7051L9.00002 11.2951C8.61002 11.6851 8.61002 12.3151 9.00002 12.7051L13.59 17.2951C13.98 17.6851 14.61 17.6851 15 17.2951C15.39 16.9051 15.39 16.2751 15 15.8851L11.12 11.9951L15 8.1151C15.39 7.7251 15.38 7.0851 15 6.7051Z"
                fill={props.fill}
            />
        </svg>
    );
};
