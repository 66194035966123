import React from 'react';
import './index.scss';
import { createPortal } from 'react-dom';
import useIsMobile from '../../hooks/use-is-mobile';

interface LeftMenuProps {
    children?: React.ReactNode;
    isOpen?: boolean;
    onClickOverlay?: () => void;
}

const LeftMenu: React.FC<LeftMenuProps> = ({ children, isOpen, onClickOverlay }) => {
    const isMobile = useIsMobile();

    if (!isMobile) {
        return null;
    }

    const handleClickOverlay = () => {
        onClickOverlay && onClickOverlay();
    };

    const content = (
        <div className={`LeftMenu ${isOpen ? '-is-open' : '-is-close'}`}>
            <div className="menu">{children}</div>
            <div className="menu-overlay" onClick={handleClickOverlay}></div>
        </div>
    );

    return createPortal(content, document.getElementById('portal-root') as Element);
};

export default LeftMenu;
