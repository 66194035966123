import { useTranslation } from 'react-i18next';
import Button from '../../../../Components/button';
import LeftMenu from '../../../../Components/left-menu';
import { CloseIcon } from '../../../../Icons';
import { FileIcon } from '../../../../Icons/file-icon';
import { LogoutIcon } from '../../../../Icons/logout-icon';
import { UserIcon } from '../../../../Icons/user-icon';
import { theme } from '../../../../Style/theme';
import useIsMobile from '../../../../hooks/use-is-mobile';
import './index.scss';
import React, { useState } from 'react';
import ManageAccount from './ManageAccount';
import BackArrow from '../../../../Components/back-arrow';
import { CONSTANTS_ROUTING } from '../../../../constants/router';
import { INITIAL_STATE_GLOBAL_PARAMS } from '../../../../reducers/global-params-reducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';

interface AccountProps {
    isOpen: boolean;
    onClickSettings: () => void;
    onClickLogout: () => void;
    onClickDeleteAccount: () => void;
    onClickOverlay: () => void;
}

export enum Tab {
    Default = 'default',
    ManageAccount = 'manage-account',
}

const Account: React.FC<AccountProps> = ({
    isOpen,
    onClickDeleteAccount,
    onClickLogout,
    onClickSettings,
    onClickOverlay,
}) => {
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const paramsStore: INITIAL_STATE_GLOBAL_PARAMS = useSelector(
        (state: RootState) => state.globalParamsReducer
    );

    const handleTabChange = (tab: Tab) => {
        setActiveTab(tab);
    };

    const [activeTab, setActiveTab] = useState(Tab.Default);
    const TOSUrl =
        paramsStore.lng === 'fr-FR' || paramsStore.lng === 'fr-CA'
            ? 'https://spreeai.com/fr/term-de-service'
            : 'https://spreeai.com/terms-of-service';

    const privacyPolicyUrl =
        paramsStore.lng === 'fr-FR' || paramsStore.lng === 'fr-CA'
            ? 'https://spreeai.com/fr/politique-de-confidentialite'
            : 'https://spreeai.com/privacy-policy';

    if (!isMobile) {
        if (!isOpen) {
            return null;
        }

        return (
            <div className="Account">
                {activeTab === Tab.Default && (
                    <React.Fragment>
                        <div className="inner">
                            <BackArrow
                                navigationTarget={CONSTANTS_ROUTING.AUTH_HOME}
                                color="element-brand-overlay-primary"
                                title={t('back')}
                                titleClassName="text-body-medium"
                            />
                            <div
                                className="tab-menu-item"
                                onClick={() => {
                                    setActiveTab(Tab.ManageAccount);
                                }}
                            >
                                <div className="icon">
                                    <UserIcon fill={theme['black']} />
                                </div>
                                <div className="text-body-large">
                                    {t('AuthHomePage.menuOption1')}
                                </div>
                            </div>
                            <a
                                className="tab-menu-item"
                                href={TOSUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="icon">
                                    <FileIcon fill={theme['black']} />
                                </div>
                                <div className="text-body-large">
                                    {t('AuthHomePage.menuOption2')}
                                </div>
                            </a>
                            <a
                                className="tab-menu-item"
                                href={privacyPolicyUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="icon">
                                    <FileIcon fill={theme['black']} />
                                </div>
                                <div className="text-body-large">
                                    {t('AuthHomePage.menuOption3')}
                                </div>
                            </a>
                        </div>

                        <div className="footer">
                            <Button
                                onClick={onClickLogout}
                                classNames="button-logout"
                                type="tonal"
                                title={t('logout')}
                                icon={{
                                    left: {
                                        icon: <LogoutIcon fill={theme['white']} />,
                                    },
                                }}
                            />
                            <div className="text-body-large" onClick={onClickDeleteAccount}>
                                {t('AuthHomePage.delete_account')}
                            </div>
                        </div>
                    </React.Fragment>
                )}

                {activeTab === Tab.ManageAccount ? (
                    <ManageAccount onTabChange={handleTabChange} />
                ) : null}
            </div>
        );
    }

    return (
        <LeftMenu isOpen={isOpen} onClickOverlay={onClickOverlay}>
            {activeTab === Tab.Default && (
                <div>
                    <div className="header" onClick={onClickSettings}>
                        <CloseIcon fill={theme['black']} width={14} />
                    </div>

                    <div className="inner">
                        <div
                            className="tab-menu-item"
                            onClick={() => {
                                setActiveTab(Tab.ManageAccount);
                            }}
                        >
                            <div className="icon">
                                <UserIcon fill={theme['black']} />
                            </div>
                            <div className="text-body-large">{t('AuthHomePage.menuOption1')}</div>
                        </div>
                        <a className="tab-menu-item" href={TOSUrl} target="_blank" rel="noreferrer">
                            <div className="icon">
                                <FileIcon fill={theme['black']} />
                            </div>
                            <div className="text-body-large">{t('AuthHomePage.menuOption2')}</div>
                        </a>
                        <a
                            className="tab-menu-item"
                            href={privacyPolicyUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="icon">
                                <FileIcon fill={theme['black']} />
                            </div>
                            <div className="text-body-large">{t('AuthHomePage.menuOption3')}</div>
                        </a>
                    </div>
                </div>
            )}

            {activeTab === Tab.ManageAccount && <ManageAccount onTabChange={handleTabChange} />}

            <div className="footer">
                <Button
                    classNames="button-logout"
                    onClick={onClickLogout}
                    type="tonal"
                    title={t('logout')}
                    icon={{
                        left: {
                            icon: <LogoutIcon fill={theme['white']} />,
                        },
                    }}
                />
                <div className="text-body-large" onClick={onClickDeleteAccount}>
                    {t('AuthHomePage.delete_account')}
                </div>
            </div>
        </LeftMenu>
    );
};

export default Account;
