import i18next from 'i18next';
import { ApiError, ApiErrors, apiErrorMapping } from '../constants/apiErrors';

function translateErrorMessage(errorMessage: string): ApiError {
    const mapKey = apiErrorMapping.get(errorMessage);

    if (!mapKey) {
        console.warn('Error message not handled! Add a mapping for this message:', errorMessage);
        return {
            errorType: ApiErrors.GENERIC,
            translatedError: i18next.t(ApiErrors.GENERIC),
        };
    }

    return {
        errorType: mapKey,
        translatedError: i18next.t(mapKey),
    };
}

export default translateErrorMessage;
