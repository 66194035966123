import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const CameraIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" {...props} fill="none">
            <path
                d="M28.0948 23.3333C28.0948 23.9647 27.8439 24.5703 27.3974 25.0168C26.9509 25.4634 26.3453 25.7142 25.7138 25.7142H4.28525C3.65378 25.7142 3.04818 25.4634 2.60166 25.0168C2.15515 24.5703 1.9043 23.9647 1.9043 23.3333V10.238C1.9043 9.60656 2.15515 9.00095 2.60166 8.55444C3.04818 8.10792 3.65378 7.85707 4.28525 7.85707H9.04715L11.4281 4.28564H18.571L20.9519 7.85707H25.7138C26.3453 7.85707 26.9509 8.10792 27.3974 8.55444C27.8439 9.00095 28.0948 9.60656 28.0948 10.238V23.3333Z"
                stroke={props.fill}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.9992 20.9525C17.6291 20.9525 19.7611 18.8205 19.7611 16.1906C19.7611 13.5607 17.6291 11.4287 14.9992 11.4287C12.3693 11.4287 10.2373 13.5607 10.2373 16.1906C10.2373 18.8205 12.3693 20.9525 14.9992 20.9525Z"
                stroke={props.fill}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
