import React, { useEffect, useRef, useState } from 'react';
import { StepProps } from '..';
import Button from '../../../Components/button';
import './step-2.scss';
import { useTranslation } from 'react-i18next';
import useServices from '../../../hooks/use-services';
import { INITIAL_STATE_GLOBAL_PARAMS } from '../../../reducers/global-params-reducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

const Step2: React.FC<StepProps> = ({ onNextStep }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [scrollProgress, setScrollProgress] = useState(0);
    const { t } = useTranslation();
    const { userService } = useServices();
    const [contentHtml, setContentHtml] = useState<React.ReactNode>(null);
    const paramsStore: INITIAL_STATE_GLOBAL_PARAMS = useSelector(
        (state: RootState) => state.globalParamsReducer
    );

    const SCROLL_PROGRESS_FOR_SUBMIT = 98;

    const calculateScrollProgress = () => {
        const component = contentRef.current;

        if (component) {
            const scrollTop = component.scrollTop;
            const scrollHeight = component.scrollHeight - component.clientHeight;
            const progress = (scrollTop / scrollHeight) * 100;

            setScrollProgress(Math.round(progress));
            return;
        }

        setScrollProgress(0);
    };

    const onSubmit = async () => {
        if (scrollProgress < SCROLL_PROGRESS_FOR_SUBMIT) return;

        onNextStep();
    };

    useEffect(() => {
        const getContent = async () => {
            const request = {
                key: 'spreeai-consent',
                category: 'html',
                language: paramsStore.lng,
            };
            const res = await userService.fetchConsent(request);
            if (res) {
                const content = new TextDecoder().decode(
                    Uint8Array.from(atob(res.value), (c) => c.charCodeAt(0))
                );
                setContentHtml(<div dangerouslySetInnerHTML={{ __html: content }}></div>);
            }
        };
        getContent();

        const card = contentRef.current;

        if (card) {
            card.addEventListener('scroll', calculateScrollProgress);

            return () => {
                card.removeEventListener('scroll', calculateScrollProgress);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="Step2 step">
            <div className="text text-headline-medium">{t('RegisterPage.step_2_title')}</div>
            <div className="text text-body-medium text-element-greys-secondary mb-40">
                {t('RegisterPage.step_2_subtitle')}
            </div>

            <div className="content-html mb-40 text-body-large" ref={contentRef}>
                {contentHtml}
            </div>

            <Button
                title={t('next').toLocaleUpperCase()}
                onClick={onSubmit}
                type="primary"
                disabled={scrollProgress < SCROLL_PROGRESS_FOR_SUBMIT}
            />
        </div>
    );
};

export default Step2;
