import React, { useState } from 'react';
import { StepProps } from '..';
import Button from '../../../Components/button';
import './step-3.scss';
import OTPReceiveCode from '../../../Components/otp-receive-code';
import { useTranslation } from 'react-i18next';
import useServices from '../../../hooks/use-services';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const Step3: React.FC<StepProps> = ({ draftUser, onNextStep }) => {
    const [code, setCode] = useState<string>('');
    // const [isCodeValid, setIsCodeValid] = useState<boolean>(false);
    const { userService } = useServices();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onSubmit = async () => {
        try {
            await userService.confirmAccount({
                email: draftUser.email,
                confirmation_code: code,
            });

            const response = await userService.loginUser({
                email: draftUser.email,
                password: draftUser.password,
            });

            dispatch({
                type: 'LOGIN',
                payload: {
                    tokens: response,
                    user: response.user_id,
                },
            });

            toast.success(t('RegisterPage.login_success') as string);
            onNextStep();
        } catch (error: any) {
            toast.error(error?.message);
        }
    };

    const onClickResendCode = async () => {
        try {
            await userService.createAccountResendCode({
                email: draftUser.email,
            });

            toast.success(t('RegisterPage.code_sent') as string);
        } catch (error: any) {
            console.error(error);
            toast.error(error?.message);
        }
    };

    return (
        <div className="Step3 step">
            <div className="text text-headline-small">{t('RegisterPage.step_3_title')}</div>
            <div
                className="text text-body-large text-element-greys-secondary mb-40"
                dangerouslySetInnerHTML={{
                    __html: t('RegisterPage.step_3_subtitle') + ' ' + draftUser.email,
                }}
            />

            <div className="wrapper-input">
                <OTPReceiveCode onSubmit={setCode} />
            </div>

            <Button title={t('confirm').toUpperCase()} onClick={onSubmit} type="primary" />
            <div className="resend-code text-body-large-bold" onClick={onClickResendCode}>
                {t('RegisterPage.resend_code')}
            </div>
        </div>
    );
};

export default Step3;
