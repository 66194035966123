import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

const FullLogoWebP = require('./../../Assets/Home/full-logo.webp');
const FullLogoWhiteWebP = require('./../../Assets/Home/full-logo-white.webp');

interface PoweredByProps {
    whiteVersion?: boolean;
}

const PoweredBy: React.FC<PoweredByProps> = ({ whiteVersion }) => {
    const { t } = useTranslation();

    return (
        <div className="PoweredBy">
            <div className="text-body-small">{t('HomePage.powered_by')}</div>
            <img
                src={whiteVersion ? FullLogoWhiteWebP : FullLogoWebP}
                alt="logo"
                width={77}
                height={18}
            />
        </div>
    );
};

export default PoweredBy;
