import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

import useServices from '../../hooks/use-services';
import { CONSTANTS_ROUTING } from '../../constants/router';
import { INITIAL_STATE_GLOBAL_PARAMS } from '../../reducers/global-params-reducer';

import PoweredBy from '../../Components/powered-by';
import { LeftChevronIcon } from '../../Icons/left-chevron-icon';
import Step1 from './steps/step-1';
import Step2 from './steps/step-2';
import Step3 from './steps/step-3';

import { theme } from '../../Style/theme';
import './index.scss';

enum Step {
    Step1,
    Step2,
    Step3,
}

interface DraftUser {
    email: string;
    password: string;
    confirmPassword?: string;
    language: string;
}

export interface StepProps {
    draftUser: DraftUser;
    handleUser: (key: string, value: string) => void;
    onNextStep: () => void;
}

const CreateAccountPage = () => {
    const nav = useNavigate();
    const { userService } = useServices();
    const location = useLocation();
    const globalParams: INITIAL_STATE_GLOBAL_PARAMS = useSelector(
        (state: any) => state.globalParamsReducer
    );
    const [step, setStep] = useState(Step.Step1);
    const [draftUser, setDraftUser] = useState<DraftUser>({
        email: '',
        password: '',
        confirmPassword: '',
        language: '',
    });

    useEffect(() => {
        // Redirect user to email confirmation page if account not confirmed
        if (location.state) {
            setDraftUser((prev) => ({ ...prev, email: location.state.email }));
            setStep(Step.Step3);
        }
    }, [location.state]); // Dependency array

    const onClickBack = () => {
        if (step === Step.Step1) {
            nav(-1);
            return;
        }

        setStep((prev) => prev - 1);
    };

    const onCreateUser = async () => {
        try {
            await userService.createUser(draftUser);
        } catch (error) {
            setStep(Step.Step1);
            throw new Error(error as any);
        }
    };

    const handleUser = (key: string, value: string) => {
        setDraftUser((prev) => ({ ...prev, [key]: value }));
    };

    const onNextStep = async () => {
        try {
            if (step === Step.Step1) {
                setDraftUser((prev) => ({ ...prev, language: globalParams.lng }));
            }

            if (step === Step.Step2) {
                await onCreateUser();
            }

            if (step === Step.Step3) {
                nav(CONSTANTS_ROUTING.AUTH_HOME);
                return;
            }

            setStep((prev) => prev + 1);
        } catch (error: any) {
            console.error(error);
            toast.error(error?.message);
        }
    };

    const propsStep: StepProps = {
        draftUser,
        handleUser,
        onNextStep,
    };

    return (
        <div className="CreateAccountPage">
            <div className="wrapper-back-icon" onClick={onClickBack}>
                <LeftChevronIcon width={24} height={24} fill={theme['black']} />
            </div>

            <div className="step-content">
                {step === Step.Step1 ? <Step1 {...propsStep} /> : null}
                {step === Step.Step2 ? <Step2 {...propsStep} /> : null}
                {step === Step.Step3 ? <Step3 {...propsStep} /> : null}
                <PoweredBy />
            </div>
        </div>
    );
};

export default CreateAccountPage;
