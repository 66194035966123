import DeepLinkService from '../Services/Deeplinks';
import TryOnService from '../Services/TryOns';
import UserService from '../Services/User';

const useServices = () => {
    const userService = new UserService();
    const tryOnService = new TryOnService();
    const deepLinkService = new DeepLinkService();

    return {
        userService,
        tryOnService,
        deepLinkService,
    };
};

export default useServices;
