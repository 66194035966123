import Modal from 'react-modal';

import useIsMobile from '../../hooks/use-is-mobile';

import Button from '../button';

import './index.scss';

interface GeneralModalProps {
    isModalOpened: boolean;
    handleLeftButton: () => void;
    handleRightButton: () => void;
    leftButtonText: string;
    rightButtonText: string;
    title: string;
}

export const GeneralModal: React.FC<GeneralModalProps> = ({
    isModalOpened,
    handleLeftButton,
    handleRightButton,
    leftButtonText,
    rightButtonText,
    title,
}) => {
    const isMobile = useIsMobile();
    Modal.setAppElement(document.getElementById('portal-root') as HTMLElement);
    return (
        <div className="GeneralModal">
            <Modal
                overlayClassName="modal-overlay"
                className="modal-container GeneralModal"
                isOpen={isModalOpened}
            >
                <div className="modal-content">
                    <h2 className="modal-title">{title}</h2>
                    <div className={isMobile ? 'modal-buttons-mobile' : 'modal-buttons'}>
                        <Button title={leftButtonText} type="outline" onClick={handleLeftButton} />
                        <Button
                            title={rightButtonText}
                            type="primary"
                            onClick={handleRightButton}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};
