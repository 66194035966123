import { combineReducers } from 'redux';
import globalParamsReducer from './global-params-reducer';
import userReducer from './user-reducer';
export type RootState = ReturnType<typeof rootReducer>

const rootReducer = combineReducers({
    globalParamsReducer: globalParamsReducer,
    userReducer: userReducer,
});

export default rootReducer;
