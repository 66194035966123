import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ShareButtonIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#a)">
                <path
                    d="M30 28.12c-.76 0-1.44.3-1.96.77l-7.13-4.15c.05-.23.09-.46.09-.7 0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7l-7.05 4.11c-.54-.5-1.25-.81-2.04-.81-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92 0-1.61-1.31-2.92-2.92-2.92Zm0-12.08c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1Zm-12 9c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Z"
                    fill="#1B1D1E"
                />
            </g>
            <rect x="4.5" y="4.5" width="39" height="39" rx="19.5" stroke="#1B1D1E" />
            <defs>
                <clipPath id="a">
                    <rect x="4" y="4" width="40" height="40" rx="20" fill="#fff" />
                </clipPath>
            </defs>
        </svg>
    );
};
