namespace UtilsParams {
    export const getEveryParameterFromWindowUrl = () => {
        try {
            const { search } = window.location;

            return getEveryParameterFromString(search);
        } catch (err: any) {
            throw new Error(err);
        }
    };

    export const getEveryParameterFromString = (url: string) => {
        try {
            const paramsObject: { [key: string]: string } = {};
            const queryString = url.split('?')[1];

            if (queryString) {
                const queryParams = queryString.split('&');

                queryParams.forEach((param) => {
                    const [key, value] = param.split('=');
                    paramsObject[key] = value;
                });
            }

            return paramsObject;
        } catch (err: any) {
            throw new Error(err);
        }
    };
}

export default UtilsParams;
