import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ForwardToInboxIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" {...props} fill="none">
            <path
                fill={props.fill}
                d="M40.625 5.208H7.292a4.179 4.179 0 0 0-4.167 4.167v25a4.179 4.179 0 0 0 4.167 4.167h18.75v-4.167H7.292V13.542l16.666 10.417 16.667-10.417v10.417h4.167V9.375a4.179 4.179 0 0 0-4.167-4.167ZM23.958 19.793 7.292 9.375h33.333L23.958 19.792Zm14.584 8.333 8.333 8.334-8.333 8.333v-6.25h-8.334v-4.167h8.334v-6.25Z"
            />
        </svg>
    );
};
