import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ShoppingBagIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="18" height="19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.5 5H12a3 3 0 1 0-6 0H4.5C3.675 5 3 5.675 3 6.5v9c0 .825.675 1.5 1.5 1.5h9c.825 0 1.5-.675 1.5-1.5v-9c0-.825-.675-1.5-1.5-1.5ZM9 3.5c.825 0 1.5.675 1.5 1.5h-3c0-.825.675-1.5 1.5-1.5Zm4.5 12h-9v-9H6V8c0 .412.338.75.75.75s.75-.338.75-.75V6.5h3V8c0 .412.338.75.75.75S12 8.412 12 8V6.5h1.5v9Z"
                fill="#fff"
            />
        </svg>
    );
};
