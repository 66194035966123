export interface ApiError {
    errorType: ApiErrors;
    translatedError: string;
}

export enum ApiErrors {
    GENERIC = 'ApiErrors.GENERIC',
    ACCOUNT_NOT_CONFIRMED = 'ApiErrors.ACCOUNT_NOT_CONFIRMED',
    INVALID_LOGIN = 'ApiErrors.INVALID_LOGIN',
    USER_NOT_FOUND = 'ApiErrors.USER_NOT_FOUND',
}

// This is used to find translations by using the error message from the server.
// The assigned value of each entry MUST match the key in Copydeck
export const apiErrorMapping = new Map<string, ApiErrors>([
    ['email unconfirmed. Please confirm email', ApiErrors.ACCOUNT_NOT_CONFIRMED],
    ['invalid login', ApiErrors.INVALID_LOGIN],
    ['Status Code: 500\n Source:service\n Message:user not found\n', ApiErrors.USER_NOT_FOUND],
]);
