import { useNavigate } from 'react-router-dom';

import { CONSTANTS_ROUTING } from '../../constants/router';
import useIsMobile from '../../hooks/use-is-mobile';

import './index.scss';
import Button from '../../Components/button';
import PoweredBy from '../../Components/powered-by';
import { useTranslation } from 'react-i18next';

const WelcomeVideoWebm = require('../../Assets/Home/Welcome.webm');
const WelcomeVideo = require('../../Assets/Home/Welcome.mp4');
const WelcomeVideoDesktopWebm = require('../../Assets/Home/WelcomeDesktop.webm');
const WelcomeVideoDesktop = require('../../Assets/Home/WelcomeDesktop.mp4');

const HomePage = () => {
    const isMobile = useIsMobile();
    const nav = useNavigate();
    const { t } = useTranslation();

    const goToLogin = () => nav(CONSTANTS_ROUTING.AUTH_PAGE);

    return (
        <div className="HomePage">
            <video className="video-container" autoPlay muted loop playsInline>
                {isMobile ? (
                    <>
                        <source src={WelcomeVideoWebm} type="video/webm" />
                        <source src={WelcomeVideo} type="video/mp4" />
                    </>
                ) : (
                    <>
                        <source src={WelcomeVideoDesktopWebm} type="video/webm" />
                        <source src={WelcomeVideoDesktop} type="video/mp4" />
                    </>
                )}
            </video>
            <div className="wrapper-informations">
                <span className="title text-headline-medium text-element-brand-rest-primary">
                    {t('HomePage.title_home_page')}
                </span>

                <div className="text-body-large description"> {t('HomePage.desc_home_page')}</div>
                <hr className="separator" />
                <Button
                    type="tonal"
                    title={isMobile ? t('HomePage.cta_mobile') : t('HomePage.cta')}
                    onClick={goToLogin}
                    classNames="next-btn"
                />
                <PoweredBy whiteVersion={isMobile} />
            </div>
        </div>
    );
};

export default HomePage;
