import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CONSTANTS } from '../../../constants/global';
import { useInterval } from '../../../hooks/use-interval';
import useIsMobile from '../../../hooks/use-is-mobile';
import useServices from '../../../hooks/use-services';
import { INITIAL_STATE_GLOBAL_PARAMS } from '../../../reducers/global-params-reducer';
import { USER_REDUCER } from '../../../reducers/user-reducer';

import QRCode from '../../../Components/qr-code';

import { theme } from '../../../Style/theme';
import './index.scss';
import BackArrow from '../../../Components/back-arrow';
import { RootState } from '../../../reducers';

const DeepLinkPage = () => {
    const userStore: USER_REDUCER = useSelector((state: any) => state.userReducer);
    const paramsStore: INITIAL_STATE_GLOBAL_PARAMS = useSelector(
        (state: RootState) => state.globalParamsReducer
    );
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const { deepLinkService, tryOnService } = useServices();

    const [qrUrl, setQrUrl] = useState('');
    const [initialLength, setInitalLength] = useState<number | null>(null);
    const [shouldStopInterval, setShouldStopInterval] = useState(false);

    const pollingInterval =
        Number(CONSTANTS.CONFIGURATION.INTERVAL_TIME_POLL_MS) || CONSTANTS.DEFAULT_POLLING_INTERVAL;

    const fetchUserTryOns = () => {
        tryOnService.fetchUserTryOns(paramsStore.partnerId).then((newData) => {
            if (initialLength !== null && newData.images.length > initialLength) {
                setShouldStopInterval(true);
                const latestTryOn = newData.images[newData.images.length - 1];
                dispatch({ type: 'SET_TRY_ON_RESULT', payload: latestTryOn.url });
            } else if (initialLength === null) {
                setInitalLength(newData.images.length);
            }
        });
    };

    useInterval(fetchUserTryOns, shouldStopInterval ? null : pollingInterval);

    useEffect(() => {
        const fetchQrCode = async () => {
            try {
                const response = await deepLinkService.createDeepLink({
                    parameters: {
                        token: userStore.tokens.access_token,
                        refreshToken: userStore.tokens.refresh_token,
                        garmentId: paramsStore.garmentId,
                        source: isMobile ? 'mobile' : 'web',
                        partnerId: paramsStore.partnerId,
                    },
                    partner_id: 'spree',
                });
                setQrUrl(response.link);
            } catch (error) {
                console.log(error);
            }
        };
        fetchQrCode();
        //eslint-disable-next-line
    }, []);

    return (
        <div className="AuthLoadingFlowQrCodePage">
            <BackArrow navigationTarget={-1} color="black" />
            <span className="text-headline-medium text-element-brand-rest-primary">
                {t('LoadingFlowQrCode.loading_flow_title')}
            </span>
            {qrUrl && (
                <div className="wrapper-qr-code">
                    <QRCode url={qrUrl} bgColor={theme['white']} size={170} />
                </div>
            )}
        </div>
    );
};

export default DeepLinkPage;
