import React, { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Router from './Router';
import { Provider } from 'react-redux';
import store from './store/store';
import { ToastContainer } from 'react-toastify';
import GlobalLoading from './Components/global-loading';

const App: FunctionComponent = () => {
    return (
        <div className="App">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div id="portal-root"> </div>

            <Provider store={store}>
                <BrowserRouter>
                    <Router />
                </BrowserRouter>

                <GlobalLoading />
            </Provider>
        </div>
    );
};

export default App;
